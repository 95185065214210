import React, { useMemo, forwardRef } from 'react'
import { variableToString, getTokenValue, useTheme, Token } from 'tamagui'
import { ActivityIndicator } from 'react-native'

export const Spinner = forwardRef<
  ActivityIndicator,
  { color: Token; size: Token }
>(({ color, size, ...props }, ref) => {
  const theme = useTheme()

  const parsedColor = useMemo(() => {
    if (color && typeof color === 'string' && color.startsWith('$')) {
      return variableToString(theme[color])
    }

    return color
  }, [color, theme])

  const parsedSize = useMemo(() => {
    return getTokenValue(size, 'size')
  }, [size])

  return (
    <ActivityIndicator
      ref={ref}
      {...props}
      size={parsedSize}
      color={parsedColor}
    />
  )
})
